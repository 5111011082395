'use strict';

var _interopRequireWildcard = require('babel-runtime/helpers/interop-require-wildcard')['default'];

var _defaults = require('babel-runtime/helpers/defaults')['default'];

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _binary = require('./binary');

var binary = _interopRequireWildcard(_binary);

exports.binary = binary;

_defaults(exports, _interopRequireWildcard(_binary));