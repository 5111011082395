'use strict';

var _interopRequireDefault = require('babel-runtime/helpers/interop-require-default')['default'];

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _lodashLangIsBoolean = require('lodash/lang/isBoolean');

var _lodashLangIsBoolean2 = _interopRequireDefault(_lodashLangIsBoolean);

exports['default'] = _lodashLangIsBoolean2['default'];
module.exports = exports['default'];