'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports['default'] = isIterator;
/**
 * Returns true if object is an iterator
 *
 * @param {*} obj
 *
 * @return {boolean}
 */

function isIterator(obj) {
  return obj && typeof obj.next === 'function';
}

module.exports = exports['default'];