'use strict';

var _interopRequireWildcard = require('babel-runtime/helpers/interop-require-wildcard')['default'];

var _defaults = require('babel-runtime/helpers/defaults')['default'];

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _isomorph = require('./isomorph');

var isomorph = _interopRequireWildcard(_isomorph);

exports.isomorph = isomorph;

_defaults(exports, _interopRequireWildcard(_isomorph));