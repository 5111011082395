'use strict';

var _interopRequireWildcard = require('babel-runtime/helpers/interop-require-wildcard')['default'];

var _defaults = require('babel-runtime/helpers/defaults')['default'];

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _classic = require('./classic');

var classic = _interopRequireWildcard(_classic);

var _degreeSequence = require('./degreeSequence');

var degreeSequence = _interopRequireWildcard(_degreeSequence);

var _randomGraphs = require('./randomGraphs');

var randomGraphs = _interopRequireWildcard(_randomGraphs);

var _small = require('./small');

var small = _interopRequireWildcard(_small);

var _social = require('./social');

var social = _interopRequireWildcard(_social);

exports.classic = classic;
exports.degreeSequence = degreeSequence;
exports.randomGraphs = randomGraphs;
exports.small = small;
exports.social = social;

_defaults(exports, _interopRequireWildcard(_classic));

_defaults(exports, _interopRequireWildcard(_degreeSequence));

_defaults(exports, _interopRequireWildcard(_randomGraphs));

_defaults(exports, _interopRequireWildcard(_small));

_defaults(exports, _interopRequireWildcard(_social));